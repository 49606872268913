import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./BlogSidebar.scss";

export default function BlogSidebar({ setSelectedCategory, setSelectedTag }) {
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetch(
      "https://backend.hnh.hu/wp-json/wp/v2/categories?_fields=id,name,slug,count"
    )
      .then((res) => res.json())
      .then((data) => setCategories(data));

    fetch(
      "https://backend.hnh.hu/wp-json/wp/v2/tags?_fields=id,name,slug,count"
    )
      .then((res) => res.json())
      .then((data) => setTags(data));

    fetch(
      "https://backend.hnh.hu/wp-json/wp/v2/posts?per_page=5&_fields=id,title,slug"
    )
      .then((res) => res.json())
      .then((data) => setRecentPosts(data));
  }, []);

  // Keresés
  const handleSearch = async (event) => {
    event.preventDefault();
    if (searchTerm.length > 2) {
      const response = await fetch(
        `https://backend.hnh.hu/wp-json/wp/v2/posts?search=${searchTerm}&per_page=5&_fields=id,title,slug`
      );
      const data = await response.json();
      setSearchResults(data);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <aside className="blog-sidebar">
      <div className="widget search-widget">
        <h3>Keresés</h3>
        <form onSubmit={handleSearch}>
          <input
            type="text"
            placeholder="Keresés..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button type="submit">🔍</button>
        </form>
        {searchResults.length > 0 && (
          <ul className="search-results">
            {searchResults.map((post) => (
              <li key={post.id}>
                <Link
                  to={`/blog/${post.slug}`}
                  dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                />
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* 🔹 Legfrissebb bejegyzések */}
      <div className="widget">
        <h3>Legfrissebb bejegyzések</h3>
        <ul>
          {recentPosts.map((post) => (
            <li key={post.id}>
              <Link
                to={`/blog/${post.slug}`}
                dangerouslySetInnerHTML={{ __html: post.title.rendered }}
              />
            </li>
          ))}
        </ul>
      </div>

      {/* 🔹 Kategóriák */}
      <div className="widget">
        <h3>Kategóriák</h3>
        <ul>
          {categories.map((cat) => (
            <li key={cat.id} onClick={() => setSelectedCategory(cat.id)}>
              <span>
                {cat.name} ({cat.count})
              </span>
            </li>
          ))}
        </ul>
      </div>

      {/* 🔹 Címkék */}
      <div className="widget tags">
        <h3>Címkék</h3>
        <div className="tag-list">
          {tags.map((tag) => (
            <span
              key={tag.id}
              onClick={() => setSelectedTag(tag.id)}
              className="tag"
            >
              {tag.name}
            </span>
          ))}
        </div>
      </div>
    </aside>
  );
}
