import { useEffect, useState } from "react";
import {
  db,
  ref,
  push,
  onValue,
  auth,
  onAuthStateChanged,
} from "../../../firebase";
import { signOut } from "firebase/auth";
import { Helmet } from "react-helmet-async";
import GoogleLogin from "../molecules/GoogleLogin";
import DentistLogin from "../molecules/DentistLogin";
import WPComments from "../organisms/WPComments";
import "./QAPage.scss";

export default function QAPage() {
  const [question, setQuestion] = useState("");
  const [questions, setQuestions] = useState([]);
  const [user, setUser] = useState(null);
  const [isDentist, setIsDentist] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (loggedInUser) => {
      setUser(loggedInUser);
      const doctorEmails = ["info@hnh.hu"];
      setIsDentist(doctorEmails.includes(loggedInUser?.email));
    });
  }, []);

  useEffect(() => {
    const questionsRef = ref(db, "questions");
    onValue(questionsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setQuestions(
          Object.entries(data)
            .map(([id, value]) => ({
              id,
              ...value,
              answers: value.answers ? Object.values(value.answers) : [],
            }))
            .sort((a, b) => b.timestamp - a.timestamp)
        );
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!user) {
      alert("🔒 Csak bejelentkezett felhasználók tehetnek fel kérdést!");
      return;
    }

    if (question.trim()) {
      push(ref(db, "questions"), {
        question,
        user: user.displayName || user.email, // 🔹 Ha van név, akkor azt mentjük, különben az email-t
        answers: null,
        timestamp: Date.now(),
      })
        .then(() => console.log("✅ Kérdés mentve!"))
        .catch((error) => console.error("❌ Hiba a mentésnél:", error));

      setQuestion("");
    }
  };

  const handleAnswer = (id) => {
    if (!isDentist) {
      alert("🔒 Csak bejelentkezett orvosok válaszolhatnak!");
      return;
    }

    const answerText = prompt("Add meg a választ:");
    if (answerText && answerText.trim()) {
      push(ref(db, `questions/${id}/answers`), answerText)
        .then(() => console.log("✅ Válasz mentve!"))
        .catch((error) => console.error("❌ Hiba a válasz mentésénél:", error));
    }
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("✅ Sikeres kijelentkezés!");
        setUser(null);
        setIsDentist(false);
      })
      .catch((error) => console.error("❌ Hiba a kijelentkezéskor:", error));
  };

  return (
    <>
      <Helmet>
        <title>A Fogorvos Válaszol | HNH Fogászat</title>
        <meta
          name="description"
          content="Tedd fel kérdésed fogorvosunknak! Kérdések és válaszok a fogászati kezelésekről, fogápolásról, és egyéb fogászati témákról."
        />
        <meta
          name="keywords"
          content="fogorvos válaszol, fogászat, fogorvos, fogászati kérdések, fogászati tanácsok, fogápolás"
        />
        <meta
          property="og:title"
          content="A Fogorvos Válaszol | HNH Fogászat"
        />
        <meta
          property="og:description"
          content="Szakértő fogorvos válaszol a kérdéseidre. Tudd meg, hogyan ápold a fogaidat és milyen kezelések segíthetnek."
        />
        <meta property="og:image" content="/og-image.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="qa-container">
        <h2>A fogorvos válaszol!</h2>
        <p>
          Ha Önnek van olyan fogászattal, fogápolással kapcsolatos kérdése, amit
          mindig szeretett volna feltenni, de sosem nyílt rá lehetősége, itt az
          alkalom, most megteheti! A kérdés feltételével google fiókkal
          jelentkezhet be az alábbi linken. A kérdése névtelenül jelenik meg, és
          nem tároljuk az Ön e-mail címét sem.
        </p>

        {!user && (
          <div>
            <GoogleLogin onLogin={(loggedInUser) => setUser(loggedInUser)} />
          </div>
        )}

        {user && (
          <div className="user-panel">
            <p>👤 Bejelentkezve: {user.displayName || user.email}</p>
            <button onClick={handleLogout}>Kijelentkezés</button>
          </div>
        )}

        {user && (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Tedd fel a kérdésed..."
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <button type="submit">Küldés</button>
          </form>
        )}

        <div className="questions-list">
          {questions.map(({ id, question, user, answers }) => (
            <div key={id} className="question">
              <p>
                <strong>Páciens kérdezte:</strong> {question}
              </p>

              {answers.length > 0 ? (
                <div className="answers">
                  {answers.map((ans, index) => (
                    <p key={index}>
                      <strong>Orvos:</strong> {ans}
                    </p>
                  ))}
                </div>
              ) : (
                isDentist && (
                  <button onClick={() => handleAnswer(id)}>Válaszolok</button>
                )
              )}
            </div>
          ))}
        </div>

        {!user && <DentistLogin onLogin={() => setUser(auth.currentUser)} />}
      </div>
      <h2>
        <br />
        Kérdések az archívumból:
      </h2>
      <div className="qa-container">
        <WPComments language="hu" />
      </div>
    </>
  );
}
