import HeroImage from "../organisms/home-components/hero-image/HeroImage";
import AboutUs from "../organisms/home-components/about-us/AboutUs";
import Doctors from "../organisms/home-components/doctors/Doctors";
import Treatments from "../organisms/home-components/treatments/Treatments";
import Testimonials from "../organisms/home-components/testimonials/Testimonials";
import Contact from "../organisms/home-components/contact/Contact";
import Map from "../organisms/home-components/map/Map";
import { Helmet } from "react-helmet-async";
import Newsletter from "../organisms/home-components/newsletter/Newsletter";
import { useTranslation } from "react-i18next";

export default function HomePage() {
  const { i18n } = useTranslation();
  const isHungarian = i18n.language === "hu";

  return (
    <>
      <Helmet>
        <title>
          Fogászat Székesfehérváron | HNH Fogorvosi Rendelő - Dr. Horváth
          Zsombor
        </title>
        <meta
          name="description"
          content="Modern, professzionális fogászati kezelések Székesfehérváron – korszerű rendelő, barátságos csapat és személyre szabott megoldások várják Önt!"
        />
        <meta
          name="keywords"
          content="fogászat, fogorvos, Székesfehérvár, fogászati kezelések"
        />
      </Helmet>

      <HeroImage />
      <AboutUs />
      <Doctors />
      <Contact />
      {isHungarian && <Treatments />}
      {isHungarian && <Newsletter />}
      <Testimonials />
      <Map />
    </>
  );
}
