import parse from "html-react-parser";
import ImageWithLightbox from "../molecules/ImageWithLightbox";

export default function PostContent({ html }) {
  const options = {
    replace: (domNode) => {
      if (
        domNode.name === "a" &&
        domNode.attribs?.href &&
        domNode.children?.length &&
        domNode.children[0]?.name === "img"
      ) {
        const imageUrl = domNode.attribs.href;
        const imgTag = domNode.children[0];
        const thumbUrl = imgTag.attribs?.src;

        // ⛔️ Ha bármelyik hiányzik, ne renderelj semmit
        if (!imageUrl || !thumbUrl) return null;

        // ✅ Csak ha .jpg, .jpeg, .png, .gif, stb. a cél
        const isImage = /\.(jpe?g|png|gif|webp)$/i.test(imageUrl);
        if (!isImage) return null;

        return <ImageWithLightbox imageUrl={imageUrl} thumbUrl={thumbUrl} />;
      }

      return undefined;
    },
  };

  return <div className="post-content">{parse(html, options)}</div>;
}
