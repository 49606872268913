import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faSquareFacebook,
  faSquareTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Container } from "react-bootstrap";
import logo from "resources/images/hnh_logo.svg";
import Popup from "reactjs-popup";
import ContactForm from "client/components/organisms/forms/contact-form/ContactForm";

import "./Footer.scss";

export default function Footer() {
  return (
    <footer>
      <Container>
        <div className="row mainFooter">
          <div className="col-md-4">
            <h3>Social Media</h3>
            <nav>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/hhdentalfogaszat"
                title="Facebook"
              >
                <FontAwesomeIcon icon={faSquareFacebook} />
                <i className="fa fa-facebook-square" aria-hidden="true"></i>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://twitter.com/H_es_H_Dental"
                title="Twitter"
              >
                <FontAwesomeIcon icon={faSquareTwitter} />
              </a>
            </nav>
          </div>
          <div className="col-md-4 middletext">
            <h3>H & H Dental</h3>
            <p>
              H-8000 Székesfehérvár, Budai út 20. <br />
              Telefon: +36 (30) 979-8436
              <br />
              <br />
              <a
                href="/fogaszat/adatkezelesi-tajekoztato"
                className="adatkezeles"
              >
                Adatkezelési tájékoztató
              </a>
            </p>
          </div>
          <div className="col-md-4">
            <img className="logo" src={logo} alt="H&H Esztétikai Fogorvoslás" />
          </div>
        </div>

        <Popup
          trigger={
            <div className="scrollContact">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
          }
          position="center center"
          modal={true}
          closeOnDocumentClick
          lockScroll={true}
        >
          {(close) => (
            <div className="contact-popup">
              <h3>Vegye fel a kapcsolatot velünk!</h3>
              <p className="declaration">
                Szívesen fogadjuk kérdéseit, de időpontot csak telefonon tud
                foglalni.
              </p>
              <a className="close" onClick={close}>
                &times;
              </a>
              <ContactForm />
            </div>
          )}
        </Popup>
      </Container>
    </footer>
  );
}
