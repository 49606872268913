import { useTranslation } from "react-i18next";
import { ReactGoogleReviews } from "react-google-reviews";
import "react-google-reviews/dist/index.css";
import "./Testimonials.scss";

export default function Testimonials() {
  const { t } = useTranslation();
  const featurableWidgetId = "a742c543-a8a0-4e87-9403-eaf1cc39a18f";
  return (
    <section className="content testimonials">
      <h2>{t("testimonials.headline")}</h2>
      <ReactGoogleReviews layout="carousel" featurableId={featurableWidgetId} />
      <a
        className="review-button"
        href="https://www.google.com/search?hl=hu-HU&gl=hu&q=Sz%C3%A9kesfeh%C3%A9rv%C3%A1r,+Dr.+Horv%C3%A1th+-+H%26H+Dental+Fogorvosi+Rendel%C5%91,+Budai+%C3%BAt+20,+8000&ludocid=13672429174120702337&lsig=AB86z5XZsCYXmfJeqngCHodn6-sj#lrd=0x4769f7a56e533713:0xbdbe39e4ec26e181,3"
        target="_blank"
      >
        {t("testimonials.add")}
      </a>
    </section>
  );
}
