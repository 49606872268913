import "./AboutUs.scss";
import aboutImg from "resources/images/5e1648bb14b47.jpg";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
  const { t } = useTranslation();
  return (
    <section className="content aboutus">
      <div className="container">
        <div className="row">
          <h2>{t("aboutus.title")}</h2>
          <div className="col-sm-6">
            <div className="img">
              <img
                className="lazyload"
                src={aboutImg}
                alt="HNH Székesfehérvár Rendelő"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="p">{t("aboutus.text")}</div>
          </div>
        </div>
      </div>
    </section>
  );
}
