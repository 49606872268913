import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./InfoBar.scss"; // <--- SCSS fájl import

export default function InfoBar() {
  const [visible, setVisible] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const isClosed = sessionStorage.getItem("infoBarClosed");
    if (isClosed === "true") {
      setVisible(false);
    }
  }, []);

  const handleClose = () => {
    sessionStorage.setItem("infoBarClosed", "true");
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="info-bar">
      <div className="info-bar__content">
        <span>{t("info_bar")}</span>
      </div>
      <button
        className="info-bar__close"
        onClick={handleClose}
        aria-label="Bezárás"
      >
        ×
      </button>
    </div>
  );
}
