import { useEffect, useState } from "react";
import axios from "axios";

export default function WPComments({ language = "hu" }) {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPageID = () => {
    switch (language) {
      case "de":
        return "1628";
      case "en":
        return "1524";
      default:
        return "393";
    }
  };

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(
          `https://backend.hnh.hu/wp-json/wp/v2/comments?post=${getPageID()}&per_page=50&page=1`
        );
        setComments(response.data);
      } catch (error) {
        console.error("❌ Hiba a kommentek lekérésekor:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [language]);

  const getReplies = (parentId) =>
    comments.filter((c) => c.parent === parentId);

  const renderComment = (comment, isReply = false) => (
    <div
      key={comment.id}
      className={`comment-card ${isReply ? "reply" : ""}`}
      style={{
        marginLeft: isReply ? "2rem" : 0,
        borderLeft: isReply ? "2px solid #ddd" : "none",
        paddingLeft: isReply ? "1rem" : 0,
      }}
    >
      <div className="card-content">
        {!isReply ? null : (
          <h5
            className="title is-6"
            dangerouslySetInnerHTML={{ __html: comment.author_name }}
          />
        )}
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: comment.content.rendered }}
        />
      </div>
    </div>
  );

  return (
    <div className="wp-comments">
      {loading ? (
        <p>Betöltés...</p>
      ) : comments.length === 0 ? (
        <p>Nincs elérhető komment.</p>
      ) : (
        comments
          .filter((comment) => comment.parent === 0)
          .map((comment) => (
            <div key={comment.id}>
              {renderComment(comment, false)}
              {getReplies(comment.id).map((reply) =>
                renderComment(reply, true)
              )}
            </div>
          ))
      )}
    </div>
  );
}
