import { useState, useEffect } from "react";
import i18n from "i18n";

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    sessionStorage.getItem("language") || i18n.language
  );

  useEffect(() => {
    if (sessionStorage.getItem("language")) {
      i18n.changeLanguage(sessionStorage.getItem("language"));
    }
  }, []);

  const chooseLanguage = (e) => {
    e.preventDefault();
    const lang = e.target.value;
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
    sessionStorage.setItem("language", lang);
  };

  return (
    <select
      value={selectedLanguage}
      onChange={chooseLanguage}
      aria-label="Nyelvválasztó"
    >
      <option value="hu">HU</option>
      <option value="de">DE</option>
      <option value="en">EN</option>
    </select>
  );
};

export default LanguageSelector;
