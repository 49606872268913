export default function Map() {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d684901.4167396119!2d16.775571054728942!3d47.890017135629584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4769f7a56e533713%3A0xbdbe39e4ec26e181!2sDr.%20Horv%C3%A1th%20-%20H%26H%20Dental%20Fogorvosi%20Rendel%C5%91!5e0!3m2!1shu!2shu!4v1674225370771!5m2!1shu!2shu"
      width="100%"
      height="450"
      title="H&H Fogorvosi Rendelő Székesfehérváron a Google Maps térképen"
      style={{ border: "0" }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}
