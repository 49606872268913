import { Outlet } from "react-router";
import Header from "client/components/organisms/Header";
import Footer from "client/components/organisms/Footer";
import BlogSidebar from "../pages/blog/BlogSidebar";
import "./BlogLayout.scss";

export default function LandingPageLayout() {
  return (
    <>
      <Header />
      <div className="blog-layout">
        <div className="container">
          <div className="content">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
