import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import "./MailchimpForm.scss";
import { Container } from "react-bootstrap";

const MAILCHIMP_URL =
  "https://hnh.us20.list-manage.com/subscribe/post?u=0b8d85b014fe44d7f48825c0e&id=1843bd84b5"; // 🔹 Használjuk az alap URL-t!

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || email.indexOf("@") === -1) {
      alert("❌ Kérjük, adja meg az érvényes email címét!");
      return;
    }

    onValidated({
      EMAIL: email,
      FNAME: firstName,
      LNAME: lastName,
    });
  };

  return (
    <section className="mailchimp-container">
      <Container>
        <h2>✉️ Iratkozzon fel hírlevelünkre! ✉️</h2>
        <p>
          Legyen mindig naprakész fogászati ajánlatainkkal, akcióinkkal és
          szakértői tanácsainkkal! Iratkozzon fel hírlevelünkre, és értesüljön
          elsőként kedvezményes kezeléseinkről, legújabb fogászati
          technológiáinkról, valamint hasznos fogápolási tippekről, amelyek
          segítenek megőrizni mosolya egészségét és szépségét!
        </p>
        <form onSubmit={handleSubmit} className="mailchimp-form">
          <input
            type="text"
            placeholder="Vezetéknév *"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Keresztnév *"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="E-mail cím *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">Feliratkozás</button>
        </form>
        {status === "sending" && <p>⏳ Küldés...</p>}
        {status === "error" && (
          <p style={{ color: "red" }}>❌ Hiba: {message}</p>
        )}
        {status === "success" && (
          <p style={{ color: "green" }}>✅ Sikeres feliratkozás!</p>
        )}
      </Container>
    </section>
  );
};

const MailchimpForm = () => {
  return (
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  );
};

export default MailchimpForm;
