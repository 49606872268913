import { Outlet } from "react-router";
import Header from "client/components/organisms/Header";
import Footer from "client/components/organisms/Footer";
import "./LandingPageLayout.scss";

export default function LandingPageLayout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}
