import ReactGA from "react-ga4";

// Google Analytics inicializálása
const GA_TRACKING_ID = "G-GBJN20SS7H";

export const initGA = () => {
  ReactGA.initialize(GA_TRACKING_ID);
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};
