import useFetch from "client/hooks/useFetch";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import "./PostPage.scss";

export default function PostPage() {
  const params = useParams();
  const post = useFetch(
    `posts?slug=${params.postId}&_fields=id,content,title,yoast_head_json`
  ).data?.[0];
  const seoData = post?.yoast_head_json;
  console.log("SEO DATA:", seoData);
  console.log("SEO Description:", seoData?.description);

  return (
    <>
      <Helmet>
        <title>{seoData?.title || post?.title?.rendered}</title>
        <meta name="description" content={seoData?.description} />
        <meta name="keywords" content={seoData?.schema?.keywords?.join(", ")} />
        <meta property="og:title" content={seoData?.og_title} />
        <meta property="og:description" content={seoData?.og_description} />
        <meta property="og:image" content={seoData?.og_image?.[0]?.url} />
        <meta property="og:url" content={seoData?.canonical} />
      </Helmet>
      <div className="post-page">
        <Container>
          <h1>{post?.title?.rendered}</h1>
          <div dangerouslySetInnerHTML={{ __html: post?.content?.rendered }} />
        </Container>
      </div>
    </>
  );
}
