import { auth, GoogleAuthProvider, signInWithPopup } from "../../../firebase";
import "./GoogleLogin.scss";

export default function GoogleLogin({ onLogin }) {
  const handleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      onLogin(result.user);
    } catch (error) {
      console.error("Hiba a bejelentkezés során:", error);
    }
  };

  return (
    <button className="google-login-button" onClick={handleLogin}>
      <img src="/google-logo.png" alt="Google logo" className="google-icon" />
      Bejelentkezés Google fiókkal
      <img src="/google-logo.png" alt="Google logo" className="google-icon" />
    </button>
  );
}
