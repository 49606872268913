import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsInEng from "resources/locales/en/translation.json";
import translationsInGerman from "resources/locales/de/translation.json";
import translationsInHungarian from "resources/locales/hu/translation.json";

// the translations
const resources = {
  en: {
    translation: translationsInEng,
  },
  de: {
    translation: translationsInGerman,
  },
  hu: {
    translation: translationsInHungarian,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: sessionStorage.getItem("language") || "hu",
  fallbackLng: "de",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  ns: "translation",
  defaultNS: "translation",
});

export default i18n;
