import { useEffect, useState } from "react";
import useFetch from "client/hooks/useFetch";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import PostContent from "../organisms/PostContent";
import "./SimplePage.scss";

export default function SimplePage() {
  const params = useParams();

  // 🔹 Első API hívás: oldal adatainak lekérése (featured_media ID-val)
  const { data } = useFetch(
    `pages?slug=${params.pageSlug}&_fields=id,content,title,yoast_head_json,featured_media`
  );

  const page = Array.isArray(data) && data.length > 0 ? data[0] : null;

  // 🔹 Második API hívás: Kiemelt kép lekérése
  const [featuredImage, setFeaturedImage] = useState(null);

  useEffect(() => {
    if (page?.featured_media) {
      fetch(`https://backend.hnh.hu/wp-json/wp/v2/media/${page.featured_media}`)
        .then((res) => res.json())
        .then((media) => {
          if (media.source_url) {
            setFeaturedImage(media.source_url);
          }
        })
        .catch((err) => console.error("Hiba a kiemelt kép lekérésekor:", err));
    }
  }, [page?.featured_media]);

  if (!page) {
    return <Container>Loading...</Container>;
  }

  const seoData = page?.yoast_head_json || {};

  return (
    <>
      {/* 🔹 SEO adatok beállítása */}
      <Helmet>
        <title>
          {seoData?.title ||
            page?.title?.rendered ||
            "Fogászati Kezelések | HNH Fogászat"}
        </title>
        <meta
          name="description"
          content={
            seoData?.description ||
            "Professzionális fogászati kezelések Székesfehérváron. Tudjon meg többet korszerű megoldásainkról!"
          }
        />
        <meta
          name="keywords"
          content={
            Array.isArray(seoData?.schema?.keywords)
              ? seoData.schema.keywords.join(", ")
              : "fogászat, fogorvos, fogászati kezelések, Székesfehérvár"
          }
        />
        <meta
          property="og:title"
          content={seoData?.og_title || page?.title?.rendered}
        />
        <meta
          property="og:description"
          content={seoData?.og_description || seoData?.description}
        />
        <meta
          property="og:image"
          content={seoData?.og_image?.[0]?.url || featuredImage}
        />
        <meta
          property="og:url"
          content={seoData?.canonical || window.location.href}
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={seoData?.canonical || window.location.href}
        />
      </Helmet>

      <Container>
        {featuredImage && (
          <div
            className="featured-image"
            style={{
              backgroundImage: featuredImage ? `url(${featuredImage})` : "none",
            }}
          ></div>
        )}
        <PostContent html={page?.content?.rendered} />
      </Container>
    </>
  );
}
