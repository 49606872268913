// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, onValue } from "firebase/database";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCvDRkAKVBPPKmBwEnemmZb2siVSG6ZkfI",
  authDomain: "hnh-kerdezd-az-orvost.firebaseapp.com",
  projectId: "hnh-kerdezd-az-orvost",
  databaseURL:
    "https://hnh-kerdezd-az-orvost-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "hnh-kerdezd-az-orvost.firebasestorage.app",
  messagingSenderId: "170296986411",
  appId: "1:170296986411:web:c9a8baddfb253b13e4d36e",
  measurementId: "G-QECMZ6638G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

export {
  db,
  ref,
  push,
  onValue,
  auth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
};
