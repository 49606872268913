import FsLightbox from "fslightbox-react";
import { useState } from "react";

export default function ImageWithLightbox({ imageUrl, thumbUrl }) {
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <img
        src={thumbUrl || imageUrl}
        alt=""
        style={{ cursor: "pointer", maxWidth: "100%" }}
        onClick={() => setToggler(!toggler)}
      />
      <FsLightbox toggler={toggler} sources={[imageUrl]} />
    </>
  );
}
