import LanguageSelector from "client/components/molecules/LanguageSelector";
import MainMenu from "client/components/molecules/MainMenu";
import logo from "resources/images/hnh_logo.svg";
import Doctor from "../molecules/Doctor";
import InfoBar from "../molecules/InfoBar";
import "./Header.scss";

export default function Header() {
  return (
    <>
      <InfoBar />
      <header>
        <div className="quickContact">
          <div className="container forQuickContact">
            <nav id="languageChooser" className="">
              <LanguageSelector />
            </nav>
            <Doctor />
          </div>
        </div>
        <div className="mainHeaderBox">
          <div className="container forMainHeader">
            <div id="mainHeader">
              <div className="row">
                <div className="col-md-4 col-xs-10">
                  <a className="mainLogo" href="/">
                    <img src={logo} alt="H&H Dental Székesfehérvár" />
                  </a>
                </div>
                <div className="col-md-8 hidden-xs hidden-sm">
                  <MainMenu />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
