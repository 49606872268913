import useFetch from "client/hooks/useFetch";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Treatments.scss";

export default function Treatments() {
  const { t } = useTranslation();
  /* Azok az oldalak fognak megjelenni, amelyeknek a wordpressben a szülője a Kezelések oldal, melynek ID-je 5247 */
  const pages = useFetch(`pages?_embed&parent=5247&order=asc`).data;
  return (
    <section className="treatments" id="home-treatments">
      <Container>
        <h2 className="treatments-headline">{t("treatments.headline")}</h2>
        <p className="treatments-text">{t("treatments.text")}</p>
        <Row xs={1} md={2} lg={3} className="g-4">
          {pages &&
            pages.map((page, index) => (
              <Col key={index}>
                <Link to={`/fogaszat/${page.slug}`}>
                  <Card
                    data-testid={`page-${index}`}
                    className="home-treatments"
                  >
                    <Card.Img
                      src={page._embedded["wp:featuredmedia"][0].source_url}
                      alt={`${page.title.rendered} fogászati kezelés`}
                    />
                    <Card.Body>
                      <Card.Title
                        dangerouslySetInnerHTML={{
                          __html: page.title.rendered,
                        }}
                      />
                      <Card.Text
                        dangerouslySetInnerHTML={{
                          __html: page.excerpt.rendered,
                        }}
                      />
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
}
