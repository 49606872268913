import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import ReactGA from "react-ga4";
import "./ContactForm.scss";

export default function ContactForm() {
  const form = useRef();
  const { t } = useTranslation();
  const notify = () =>
    toast.success("✉️ " + t("toast.messageSent"), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_phlc653", "template_qskmdc9", form.current, {
        publicKey: "2A2ts98LDoEWpSkbM",
      })
      .then(
        () => {
          e.target.reset();
          notify();
          ReactGA.event({
            category: "Contact Form",
            action: "User Sent out a Contact form",
          });
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail} className="contact-form">
        <div className="form-group">
          <label for="from_name">{t("contactForm.name")}</label>
          <input type="text" name="from_name" id="from_name" />
        </div>
        <div className="form-group">
          <label for="from_email">E-mail</label>
          <input type="email" name="from_email" id="from_email" />
        </div>
        <div className="form-group">
          <label for="from_tel">{t("contactForm.phone")}</label>
          <input type="text" name="from_tel" id="from_tel" />
        </div>
        <div className="form-group">
          <label for="message">{t("contactForm.message")}</label>
          <textarea name="message" id="message" />
        </div>
        <button type="submit" className="btn btn-primary">
          {t("contactForm.button")}
        </button>
      </form>
      <ToastContainer />
    </>
  );
}
