import { useTranslation } from "react-i18next";
import ContactForm from "client/components/organisms/forms/contact-form/ContactForm";

export default function Contact() {
  const { t } = useTranslation();
  const content = t("contact.container.content");
  return (
    <section className="content contact">
      <div className="container">
        <div className="row">
          <h2>{t("contact.container.title")}</h2>
          <div className="col-md-6 text">
            <div className="p" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <div className="col-md-6">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
}
