import { useEffect, useState } from "react";

export default function useFetch(urlParams) {
  const [data, setData] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const baseUrl = "https://backend.hnh.hu/wp-json/wp/v2/";
  const url = baseUrl + urlParams;
  useEffect(() => {
    async function loadData() {
      const response = await fetch(url);
      if (!response.ok) {
        // oups! something went wrong
        return;
      }
      setPageCount(response.headers.get("x-wp-totalpages"));
      const data = await response.json();
      setData(data);
    }

    loadData();
  }, [url]);
  return { data, pageCount };
}
