import { Container } from "react-bootstrap";
import { scrapeHtmlWeb } from "scrape-html-web";

export default function ScraperPage() {
  //example
  const options = {
    url: "https://adatbank.mlsz.hu/club/63/8/29621/9/289033.html",
    bypassCors: true, // avoids running errors in esm
    mainSelector: ".standings_box",
    list: false,
    childrenSelector: [
      {
        key: "datas",
        selector: "table",
        type: "html",
      },
    ],
  };

  (async () => {
    const data = await scrapeHtmlWeb(options);
    console.log(data);
  })();

  return (
    <Container>
      <h1>Lets Scrape!</h1>
    </Container>
  );
}
